* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Roboto', sans-serif;
}

body,
html,
#app {
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  font-size: 14px;
  overflow-x: hidden;
  background: '#FFF';
}

input {
  font-size: 14px;
}

button {
  cursor: pointer;
  font-size: 14px;
  border: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ec9323;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c77611;
}
